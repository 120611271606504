import { useEffect, useState, useRef } from "react";
import 
    {
        Table,
        Button,
        Collapse,
    }
 from 'react-bootstrap';
import Member from './Member'; 
import useRootClose from 'react-overlays/useRootClose';
import { getDocuments } from '../../data/documents'
import firebase from "../../firebase";

function MemberRow(props){
    const member = props.member
    const [show,setShow] = useState(false);

    const isAdminUser = props.userPrivileges?.includes('admin')

    const memberDetail = useRef();
    useRootClose(memberDetail.current, () => setShow(false), {
        disabled: !show,
    });

    return(
        <>
            <a href='#' onClick={()=>setShow(true)}><b>{member.fName} {member.lName}</b></a>
            
            <Collapse in={show} >
                <div ref={memberDetail}>
                    <Member  member={member} snapshot={!isAdminUser}/>
                </div>
                
            </Collapse>
        </>
    )
} 

function ListMembers(props) {
    const [members,setMembers] = useState([]);
    
    const getMembers = async () => {
        setMembers(await getDocuments('members','limit=5000'))
    }

    const currentUser = firebase.auth().currentUser
    useEffect(() => {
        getMembers()        
    },[currentUser]) //add a dependency on current user since the data changes based on user previleges

    return (

        <div style={{display: props.display?"block":"none"}}>
            
            <div style={{textAlign:'right', display: props.userPrivileges.includes('admin')?'block':'none' }}>
                <Button onClick={(e) => props.updateBody(e,'addMember')} > Add New Member </Button>
            </div>
            
            <h4>Members</h4>
            <Table bordered responsive striped>
                <thead>
                    <tr>
                        <th>#</th>                        
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                {
                members.map((member,idx) => (
                    <tr>
                        <td style={{width:'3rem'}}>{idx+1}</td>                        
                        <td><MemberRow member={member} userPrivileges={props.userPrivileges} /></td>
                    </tr>

                ))
            }

                </tbody>
            </Table>       
           
        </div>
    )

}

export default ListMembers;