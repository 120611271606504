import { Component } from 'react';
import {Container, Nav, Navbar, NavDropdown, Offcanvas, Row, Col } from 'react-bootstrap';
import { FiUser } from "react-icons/fi";
import Brand from '../Brand';
import UserMenu from './UserMenu';
import ImageLink from "../widgets/ImageLink";
import Config from '../resources/config.json';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userSignedIn: false,
    };
    
  }
  
  scrollToBottom = () =>{  
    window.scrollTo({  
      top: document.documentElement.scrollHeight,  
      behavior: 'smooth'
    });  
  }; 


  render() {
    
    return (
      <>
        
        <Navbar 
          collapseOnSelect 
          key={this.props.expand} 
          expand={this.props.expand} 
          className="bg-black flex-nowrap" 
          sticky="top" 
          data-bs-theme="dark"
          style={{opacity:"1"}}
          >
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${this.props.expand}`} />

          <Container className="d-block d-md-block ">
            <Brand updateBody={this.props.updateBody} />

            <Container >               
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${this.props.expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${this.props.expand}`}
                placement="start"
                className="bg-black"
                data-bs-theme="dark"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${this.props.expand}`} >
                    <img src={Config.brand.logo} width="40" height="40"  alt=""/>    
                  </Offcanvas.Title> 
                </Offcanvas.Header>
                <Offcanvas.Body className='justify-content-center'>
                  <Nav className='text-uppercase' >
                    <Nav.Link href="#home" onClick={(e) => this.props.updateBody(e,"home")} >Home</Nav.Link>
                    <Nav.Link href="#news_n_events" onClick={(e) => this.props.updateBody(e,"news_n_events")}>News & Events</Nav.Link>
                    <Nav.Link href="#apparatus" onClick={(e) => this.props.updateBody(e,"apparatus")}>Apparatus</Nav.Link>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
             
            </Container>
          </Container>  
          <Nav className="justify-content-end m-1">
            {
            this.props.userDetails.email  ?               
              <Nav.Link href="#user" >
                <UserMenu user={this.props.userDetails} updateBody={this.props.updateBody}/>
              </Nav.Link> 
            :<Nav.Link href="#login" onClick={(e) => this.props.updateBody(e,"login")}>
                <FiUser /> <span className={`d-none d-${this.props.expand}-inline`}> Login </span>
              </Nav.Link>   
            }    
          </Nav>     
        </Navbar>
        
        
        {/* Quick Links to be appeared on each page*/}
        <div style={{position:'relative', width:'100%', textAlign:'center'}}>
            <a href='/memberportal'><h1 style={{height:'10rem'}} className='bg-dark text-light'>Member Portal</h1></a>
         
            <Row style={{top:'50%', left:'50%', position:'absolute', transform:'translate(-50%, -50%)',msTransform:'translate(-50%, -50%)', width:'20rem'}} className='g-1'>
            {/*
              Config.postTypes.map((postType,idx) => (
                <Col key={postType.type} style={{display:this.props.bodyType===postType.type?'none':''}}>
                  <ImageLink 
                    hideImage url="#" 
                    onClick={(e) => this.props.updateBody(e,postType.type)}  
                    icon={postType.icon}
                    />
                </Col>
              ))
              */}
            
            </Row>
        
           
        </div>


      </>
    );
  }
  
}

export default Header;

