import firebase from "../firebase"

export const saveFile = async (file,path) => {
    if(!(firebase.auth().currentUser)) throw new Error("User not authorized.")

    const formData = new FormData();
    formData.append("file",file);
    return await fetch(`${process.env.REACT_APP_API_URL}/obj/m/${path}/${file.name}`,
    {
        method: 'post',
        headers: {
            'Authorization': await firebase.auth().currentUser.getIdToken(true),
            'X-Firebase-AppCheck': (await firebase.appCheck().getToken(/* forceRefresh= */ true)).token,
        },
        body: formData
    })
    .then(
        (res) => {
            if(res.ok){
                return true;
            }else{
                return false;
            }
            
        },
        (error) => {
            console.error(error);
            throw new Error(error)
                               
        }
    )
    .catch((e)=>{
        console.error(e);
        throw new Error(e)
    })
}