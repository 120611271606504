import { Image } from "react-bootstrap";
import { useEffect, useState, useRef } from "react";
import ShowMembers from './collections/members/ShowMembers';
import firebase from './firebase';
import Config from './resources/config.json';

function AboutUs(props) {
    

    const isLoaded = useRef(false);
    const [content, setContent] = useState([]);

    // Note: the empty deps array [] means
    // this useEffect will run once
    // similar to componentDidMount()
    
    useEffect(() => {
        firebase.appCheck().getToken(/* forceRefresh= */ true)
        .then((appCheckTokenRes) => {
          //console.log(appCheckTokenRes.token)
          fetch(process.env.REACT_APP_API_URL+"/obj/static/AboutUs.txt",
          {
            method: 'get',
            headers: {'X-Firebase-AppCheck':appCheckTokenRes.token},
          }
          )
          .then(res => res.text())
          .then(
            (result) => {
              isLoaded.current = true;
              setContent(result);
              //console.log("fetched content");
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              isLoaded.current = true;
              setContent("error");
              console.error(error);
            }
          )
        })    
          
    }, [])
   
      return (
        <div style={{display: props.display?"block":"none"}}>
          <div style={{textAlign: "center"}} className="m-3 rounded">
            <Image src={Config.brand.logo} width="100" height="100" alt="" />
          </div>  
  
          {isLoaded.current ? <p>{content}</p>:<></>}
          <br></br>
          <ShowMembers />
        </div>
    
      );
  }
  
  export default AboutUs;