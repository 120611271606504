import { useState, useEffect } from "react";
import { Chart } from "react-google-charts";
import { getDocumentById , getDocuments } from './data/documents'

const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

function CallVolumeChart(props) {
    const chartColor = 'rgb(255, 153, 0)';
    const [chartData, setChartData] = useState([]);
    const chartOptions = {
        title: "Calls Volume",
        backgroundColor: "rgb(50,50,50)",
        legend: { position: "none" },
        titleTextStyle: {
            color: chartColor,
        },
        hAxis: {
            textColor: chartColor,
        },
        vAxis: {
            textColor: chartColor,
            viewWindow: {
                min:0
              }
        },
    }
    const fetchCallVolume = async function (fromYear, toYear) {
        //fetch from "calls.volume" database
        const incidents = {};
        for(var year=fromYear; year<=toYear; year++){
            const callsVolume = await getDocumentById('calls.volume',year)
            delete callsVolume['id'] //remove the id field

            if(Object.keys(callsVolume).length >0){
                for(var month in callsVolume) {
                    if(!incidents[year]){
                        incidents[year] = {}
                    }
                    incidents[year][month] = callsVolume[month];
                }  
                
            }
        }

        //fetch data from "incidents" database
        await getDocuments('incidents',`q=startTime>=${new Date(fromYear,0,1,0,0,0,0).getTime()} AND startTime<${new Date(toYear,12,1,0,0,0,0).getTime()}&orderBy=startTime,asc&limit=-1`)
        .then ( (results) => {
            results.map((incident,idx) => {
                const year = new Date(incident.startTime).getFullYear();
                const month = months[new Date(incident.startTime).getMonth()];

                if(!incidents[year]){
                    incidents[year] = {}
                }
                if(!incidents[year][month]){
                    incidents[year][month] = 1
                }else{
                    incidents[year][month]++;
                }
            });  
        })
        .catch( (e) => {
            console.error(e);
        });

        var data = [
            ["Month","Calls Volume",{ role: "style" }],
        ];
        for(var year in incidents){
            for(var month in incidents[year]) {
                data.push([month+" "+year, incidents[year][month], "color: "+chartColor])
            }
        }

        
        if(data.length === 1) {
            data = [
                ["Month",{ role: 'annotation' }],
                ['','no data']
            ];
            
        }
        
        setChartData(data);
    }

    useEffect(() => {
        fetchCallVolume(props.fromYear, props.toYear);
    }, [props.fromYear, props.toYear])
    

    return (
        <>
            {chartData.length > 0 ?
            <Chart chartType="ColumnChart" width="100%" height="200px" data={chartData} options={chartOptions}/>
            :<></>
            }
            
        </>
    );
    
}

export default CallVolumeChart;
