import { useEffect, useState } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getDocuments, deleteDocument } from '../../data/documents';
import Incident from "./Incident";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

function ShowIncidents(props) {
    const [incidents, setIncidents] = useState([]);
    var incidentFromTime = props.fromTime??new Date().setHours(0,0,0,0) //default is current day's 12 AM
    var incidentToTime = props.toTime??new Date().getTime()

    //get time in yyyy-MM-ddThh:mm format in local time zone
    const localFromTimeStr = new Date(incidentFromTime - new Date().getTimezoneOffset()*60*1000).toISOString().substring(0,16)
    const localToTimeStr = new Date(incidentToTime - new Date().getTimezoneOffset()*60*1000).toISOString().substring(0,16)
    
    const submitForm = (event) => {
        event.preventDefault();
        event.stopPropagation();
        try{
            
            const form = event.currentTarget;
            incidentFromTime = new Date(form.fromTime.value).getTime()
            incidentToTime = new Date(form.toTime.value).getTime()
            fetchIncidents()

        }catch(e){
            console.error(e)
        }
    }

    const fetchIncidents = () => {
        getDocuments('incidents',`q=startTime>=${incidentFromTime} AND startTime<=${incidentToTime}&orderBy=startTime&limit=-1`)
        .then ( (results) => {
            setIncidents(results)
        })
        .catch( (e) => {
            console.error(e);
            setIncidents([]);
        });
    }

    const deleteIncident = async (incidentId) => {
        deleteDocument('incidents',incidentId)
        .then((status) => {
          if(status) {
            const newIncidents = [];
                for(let i=0; i<incidents.length; i++){
                  if(incidents[i].id !== incidentId) {
                    newIncidents.push(incidents[i]);            
                  }
                }
                setIncidents(newIncidents);
          }
        })
        .catch((e) => {
          window.alert("Something went wrong. The incident could not be deleted. Please try later.");
        })
        
        }

    useEffect(() => {
        //fetch incidents
        console.log("fetching incidents...")
        fetchIncidents(); 
      },[]);

    return (
        <div>
            <Form onSubmit={submitForm} style={{display: props.allowRangeSelection?'block':'none'}}>
                <Row xs={1} md={3}>
                    <Col>
                        <FloatingLabel controlId='fromTime' label='From' className='m-1'>
                            <Form.Control 
                            type="dateTime-local" 
                            required={true}
                            defaultValue={localFromTimeStr}
                            /> 
                        </FloatingLabel>
                    </Col>
                    <Col>
                        <FloatingLabel controlId='toTime' label='To' className='m-1'>
                            <Form.Control 
                            type="dateTime-local" 
                            required={true}
                            defaultValue={localToTimeStr}
                            /> 
                        </FloatingLabel>
                    </Col>
                    <Col>
                        <Button className='m-1' variant="primary" type="submit" > Get Incidents </Button>
                    </Col>
                </Row>
            </Form>    

            <Row xs={1} >   
            {
            incidents.map((incident, idx) => (
                <Col key={incident.id}>
                    <Incident 
                        incident={incident} 
                        userPrivileges={props.userDetails?.privileges??[]} 
                        deleteIncident={deleteIncident}
                        show={idx===0?true:false}
                    />
                </Col>
            ))
            }
            </Row> 
        </div>
    )

}

export default ShowIncidents;