import { Container } from "react-bootstrap";
import Home from "./Home";
import React from "react";
import { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import AboutUs from "./AboutUs";
import ShowPosts from "./collections/posts/ShowPosts";
import Apparatus from "./Apparatus";
import JoinUs from "./JoinUs";
import CallHistory from "./CallHistory";
import NewStation from "./NewStation";
import Donate from "./widgets/Donate.js";
import ShowAlerts from "./collections/alerts/ShowAlerts";
import StaticContent from "./StaticContent";
import Config from './resources/config.json';
import Subscribe from "./widgets/Subscribe.js";

class Body extends Component {
    constructor(props) {
        super(props);
        this.state = {
          bodyType: '',
        };
    
      }
    
    previousBodyType= "";  
    updateBody = (event,bodyType)  => {
        console.log(bodyType)
        if(event != null) event.preventDefault();
        const a = bodyType.split('#');
        this.previousBodyType = bodyType;
        this.setState({bodyType: a[0]});  

        //scroll to the requested element
        const elementToScrollIntoView = document.getElementById(a[1]); 
        if(elementToScrollIntoView) {
          elementToScrollIntoView.scrollIntoView()  
        }          
    } 

    componentDidMount() {
      this.updateBody(null,this.props.bodyType)
    }

  
    
    render() {
        console.log("rendering..."+this.state.bodyType)
        // return the document
        return (
            <>
              <Header 
                expand="xl"
                updateBody={this.updateBody}
                bodyType={this.state.bodyType}
              />
                
              <Container  id='_body' >
                <div id='alerts-conatiner'><ShowAlerts /></div>
                <Home  display={this.state.bodyType==="home" ? true : false} updateBody={this.updateBody} />
                {this.state.bodyType==='about_us' ? <AboutUs display={true} />:<></>}
                {this.state.bodyType==='donate' ? <StaticContent display={true} contentPath="/static/Donate.html" />:<></>}
                {this.state.bodyType==='apparatus' ? <Apparatus display={true} />:<></>}
                <JoinUs display={this.state.bodyType==="join_us" ? true : false} />
                <CallHistory display={this.state.bodyType==="call_history" ? true : false} />
                <NewStation display={this.state.bodyType==="new_station" ? true : false} />
                
                {/* ---------- All dynamic pages : start --------- */}
                {
                
                  Config.postTypes.map((postType,idx) => (
                    // Re-render in order to re-fetch all posts each time the page is visited.
                    this.state.bodyType===postType.type ? 
                    <ShowPosts
                      id={idx} 
                      key={postType.type} 
                      display={true} 
                      postType={postType.type} 
                    /> 
                    : <></>
                  ))
 
                }
                {this.state.bodyType==="news_n_events" ? <ShowPosts display={true}  /> :<></>}
                

                {/* ---------- All dynamic pages : end --------- */}

                <div className="sticky">
                  <Donate />
                  
                </div>
                
              </Container>  

              <Footer updateBody={this.updateBody}/>
            </>
        
          );
    }
    
  }
  
  export default Body;