import {
    Row,
    Col,
    Card,
} from 'react-bootstrap'
import DataView from '../../widgets/DataView'
import DataForm from '../../widgets/DataForm'
import {useState} from 'react'

function Member(props) {
    const [currentMember, setCurrentMember] = useState(props.member)
    const [editPersonalInfo, setEditPersonalInfo] = useState(false)

    return (
        <>
            {props.snapshot?
            <Row className="justify-content-center m-1">
            <Col xs='auto'>

                <Card id='profilePic' bg='dark' text="light" style={{height:'14rem', width:'14rem', border:'0'}}>
                    <Card.Img style={{maxHeight:'14rem', maxWidth:'14rem'}} variant="top" src={process.env.REACT_APP_API_URL+"/obj"+currentMember.picUrl} />
                </Card>
            </Col>
            <Col>
            
                <Card id='officialInfo' >
                    <Card.Body>
                        <Card.Title><label>{currentMember.email}</label></Card.Title>
                        <Card.Text className='text-secondary'>
                            <Row xs='auto'><Col><label>ID:</label></Col><Col> {currentMember.memberId}</Col></Row>
                            <Row xs='auto'><Col><label>Member Since:</label></Col><Col> {new Date(currentMember.startDt).toDateString()}</Col></Row>
                            <Row xs='auto'><Col><label>Org Roles:</label></Col><Col> {currentMember.orgRoles.map((role,idx)=>(<div>{role}</div>))}</Col></Row>
                        </Card.Text>                                               
                    </Card.Body>
                </Card>
            
            </Col>
        </Row> 
        :
        <div>  
            <img 
                src="/edit.png" 
                alt='upload' 
                style={{maxHeight:'30px', display:editPersonalInfo?'none':'block', float:'right'}} 
                onClick={(e) => setEditPersonalInfo(true)}
            />
            {editPersonalInfo ?
                <DataForm 
                    doc={currentMember} 
                    docType='members' 
                    formAction='edit' 
                    callbackSave={(member) => {
                        setCurrentMember(member)
                        setEditPersonalInfo(false)
                    }}
                    callbackCancel={(e) => setEditPersonalInfo(false)} 
                />
                :
                <DataView dataType='members' data={currentMember} title='Member Details' />
            }
        </div>
        
        }       
            
        </>
    )

}

export default Member;