import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import DataForm from '../../widgets/DataForm';

function CreatePost(props) {
    const [show, setShow] = useState(false);
    
    const closeUploadForm = () => {
        setShow(false);
    }
    const showUploadForm = () => {
        setShow(true);
    }

    
    // this is called when a new post is created.
    const postCreated = (post) => {
        props.updateBody(null,post.type)
    };
    
    return (
        
        <>
            <button onClick={showUploadForm} style={{backgroundColor:'transparent', cursor:'pointer', border:'0'}}>
                <Image src='/Post.png' alt='Create Post' style={{maxHeight:'3rem'}} />
            </button>
            
            <Modal 
                show={show} 
                onHide={closeUploadForm} 
                size="lg" 
                backdrop="static"
            >
                <Modal.Header closeButton className='text-dark'>
                    <Modal.Title>Create a new post</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-dark'>
                    <DataForm  docType='posts' formAction='add' callbackSubmit={postCreated} callbackCancel={(e)=>setShow(false)} />
                </Modal.Body>

            </Modal>

        </>
        
    );
   
}

export default CreatePost;