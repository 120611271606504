import Overlay from 'react-bootstrap/Overlay';
import { useState, useEffect, useRef } from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import ListGroup from 'react-bootstrap/ListGroup';
import firebase from '../firebase';


function UserMenu(props) {
    const [show, setShow] = useState(false);
    const userMenu = useRef(null);
    const updateBody= props.updateBody;

    const userInitials = props.user.fName.substring(0,1)+props.user.lName.substring(0,1);
    const userName = props.user.fName+" "+props.user.lName;
    const userEmail = props.user.email;

    const handleClickOutside = (event) => {
        if (userMenu.current && !userMenu.current.contains(event.target)) {
            setShow(false);
        }
    };

    useEffect(() => {
          document.addEventListener('click', handleClickOutside, true);
          return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    },[]);
    

    return (
        <>
       
        <div style={{position:"relative", textAlign:"center"}} ref={userMenu} onClick={() => setShow(!show)} >
            <span className='bg-dark' style={{height:'30px', width:'30px', borderRadius:'50%', display:'inline-block' }}></span>
            <span className="text-uppercase" style={{position:'absolute', top:'10%', left:'20%'}} >{userInitials}</span>
        </div>

        <Overlay target={userMenu.current} show={show} placement="bottom">
        {(props) => (
            <Tooltip id="user-menu" {...props}>
                <ListGroup variant="flush" >
                    <ListGroup.Item className='bg-black text-light'>
                        <b>{userName}</b>
                        <div>{userEmail}</div>
                    </ListGroup.Item>
                    <ListGroup.Item className='bg-black text-light' action href="" onClick={(e) => updateBody(e,'user_account')}>
                        Member Info
                    </ListGroup.Item>
                    <ListGroup.Item className='bg-light text-dark' action onClick={() => firebase.auth().signOut()}>Sign Out</ListGroup.Item>
                
                </ListGroup>
                
            </Tooltip>
            
        )}
        </Overlay>
        </>
    );
}

export default UserMenu;