import { BrowserRouter, Routes, Route } from "react-router-dom";
//import Body from './Body copy.js';
import Body from './Body.js';
import MemberPortal from './MemberPortal/MemberPortal'
import ShowPost from './collections/posts/ShowPost'
import Subscribe from "./widgets/Subscribe.js";

function App(props) {
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/memberportal" element={<MemberPortal bodyType='home'/>} /> 
        <Route path="/about_us" element={<Body bodyType='about_us'/>} /> 
        <Route path="/contact_us" element={<Body bodyType='contact_us'/>} />
        <Route path="/members" element={<Body bodyType='about_us#ShowMembers'/>} /> 
        <Route path="/posts/ShowPost/:id" element={<ShowPost />} /> 
        <Route path="/unsubscribe/:email" element={<Subscribe unSubscribe={true}/>} /> 
        <Route path="*" element={<Body bodyType='home' />} />        
      </Routes>
    </BrowserRouter>
  );
}

export default App;
