import {
    Row,
    Col,
    Container,
    Image,
} from 'react-bootstrap';
import schema from '../data/schema.json'


function DataView(props) {
    const title = props.title
    const doc = props.data
    const dataType = props.dataType

    const renderField = (field, data) => {
        switch (field.type) {
            case 'map':
                return  (
                    <>
                        <label>{field.description??field.name}:</label>
                        <Container fluid className="mb-3 border rounded" >
                        {field.subFields.map((subField,idx) => (
                                renderField(subField, data?.[field.name])
                            ))
                        }                            
                        </Container>           
                    </>
                )
                
            default:
                if(field.picture){
                    return (
                        <Row xs='auto' className='mb-1'>
                            <Col><label>{field.description??field.name}:</label></Col>
                            <Col>
                                <Image style={{width:'10rem', height:'10rem'}} fluid src={`${process.env.REACT_APP_API_URL}/obj${data[field.name]}`} alt={field.name}/>
                            </Col>
                        </Row>
                    )
                }else {
                    return (
                        <Row xs='auto' className='mb-1'>
                            <Col><label>{field.description??field.name}:</label></Col>
                            <Col> {field.multiple?
                                data?.[field.name]?.map((value,idx) => (<div>{value}</div>))
                                :data?.[field.name]?data[field.name]+"":""
                            }
                            </Col>
                        </Row>
                    )
                }
        }
    }

    return (
        <Container className='border rounded' >
            <h4 className='text-center'>{title}</h4>
            {schema[dataType].map((field,idx) => (
                renderField(field,doc)
            ))
            }
        </Container>
    )
}

export default DataView 