import React from 'react'
import { Autocomplete, GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import {
    Form,
    Container,
    Button,
    Row,
    Col
} from 'react-bootstrap';

const libs = ['places', 'marker']

function LocationChooser(props) {
    const id = props.id ?? 'map'
    const editable = props.editable ?? false

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
        libraries: libs
    })

    const [map, setMap] = React.useState(null)
    const [autocomplete, setAutocomplete] = React.useState();
    const [center, setCenter] = React.useState(props.position);
    var [enableAddress, setEnableAddress] = React.useState(false);
    const markerRef = React.useRef();
    const address = React.useRef();
    const coordinate = React.useRef();
    
    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.setZoom(map.zoom)
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    const getCurrentMarkerLocation = async (event) => {
        const c = markerRef.current.marker.position.toJSON()
        setCenter(c)
        coordinate.current.value = JSON.stringify(c)
        address.current.value = await getAddress(c.lat, c.lng)
    }

    const placeSelected = () => {
        setCenter(autocomplete.getPlace().geometry.location.toJSON())
        coordinate.current.value = JSON.stringify(center)
    }

    const getCurrentGeoLocation = () => {
        console.log("getting current location...")
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(async (position) => {
                const c = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                }

                setCenter(c);
                coordinate.current.value = JSON.stringify(c)
                address.current.value = await getAddress(c.lat, c.lng)
               
            });
        } else {
            window.alert("Geolocation is not supported by this browser.");
        }
    }

    const getAddress = async (lat, lng) => {
        if(enableAddress){
            const address = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
            {
                method: 'get',
            })
            .then((res) => res.json())
            .then((res) => {
                return res.results[0].formatted_address
            })
            .catch((error) => {
                console.error(error)
                return ''
            });

            return address;
        }
        
        return '';
    }

    React.useEffect(() => {
       if(!props.position) getCurrentGeoLocation()
    }, [])

    return isLoaded ? (
        <Container class='m-2'>
            {editable ?
            <>
            <Button variant="secondary" style={{ width: '100%' }} onClick={getCurrentGeoLocation}> current location </Button>
            <Row xs={1}>
                <Col>
                    <label>Enable Address</label>
                    <input
                        type='checkbox' 
                        id={`${id}.enableAddress`} 
                        checked={enableAddress}
                        onChange={async (e) => {
                            enableAddress = !enableAddress
                            setEnableAddress(enableAddress)
                            address.current.value = await getAddress(center.lat, center.lng)  
                        }}
                        disabled={center ? false : true}
                    /> 
                </Col>
                <Col>
                    <Autocomplete
                        onLoad={setAutocomplete}
                        onPlaceChanged={placeSelected}
                    >
                        <Form.Control type='text' id={`${id}.address`} placeholder='Type Address' ref={address} disabled={!enableAddress}/>
                    </Autocomplete>
                </Col>
                
            </Row>
            <Form.Control is='coordinate' type='hidden' id={`${id}.coordinate`} ref={coordinate} value={JSON.stringify(center)} />
            </>
            :<></>}
            
            <GoogleMap
                mapContainerStyle={props.style}
                center={center}
                zoom={16}
                onLoad={onLoad}
                onUnmount={onUnmount}
                options={{ 
                    streetViewControl: false,
                    mapTypeControlOptions: {
                        style: window.google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                        position: window.google.maps.ControlPosition.TOP_LEFT,
                      },
                    styles: [
                        { elementType: "geometry", stylers: [{ color: "#1d1c1c" }] },
                        { elementType: "geometry.stroke", stylers: [{ color: "#7a7b7c" }] },
                        { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
                        { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
                        {
                        featureType: "administrative.locality",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#d59563" }],
                        },
                        {
                        featureType: "poi",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#d59563" }],
                        },
                        {
                        featureType: "poi.park",
                        elementType: "geometry",
                        stylers: [{ color: "#263c3f" }],
                        },
                        {
                        featureType: "poi.park",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#6b9a76" }],
                        },
                        {
                        featureType: "road",
                        elementType: "geometry.fill",
                        stylers: [{ color: "#2d2c2c" }],
                        },
                        {
                        featureType: "road",
                        elementType: "geometry.stroke",
                        stylers: [{ color: "#7a7b7c" }],
                        },
                        {
                        featureType: "road",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#7a7b7c" }],
                        },
                        {
                        featureType: "road.highway",
                        elementType: "geometry",
                        stylers: [{ color: "#746855" }],
                        },
                        {
                        featureType: "road.highway",
                        elementType: "geometry.stroke",
                        stylers: [{ color: "#1f2835" }],
                        },
                        {
                        featureType: "road.highway",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#f3d19c" }],
                        },
                        {
                        featureType: "transit",
                        elementType: "geometry",
                        stylers: [{ color: "#2f3948" }],
                        },
                        {
                        featureType: "transit.station",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#d59563" }],
                        },
                        {
                        featureType: "water",
                        elementType: "geometry",
                        stylers: [{ color: "#17263c" }],
                        },
                        {
                        featureType: "water",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#515c6d" }],
                        },
                        {
                        featureType: "water",
                        elementType: "labels.text.stroke",
                        stylers: [{ color: "#17263c" }],
                        },
                    ]
                }}
            >
                { /* Child components, such as markers, info windows, etc. */}
                <Marker ref={markerRef}
                    position={center}
                    draggable={editable}
                    //icon='Incident.png'
                    onDragEnd={getCurrentMarkerLocation}
                />
            </GoogleMap>
            
        </Container>

    ) : <></>
}


export default LocationChooser;
