import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import ReactDOM from 'react-dom/client';
import ShowAlerts from './ShowAlerts';
import DataForm from '../../widgets/DataForm';

function CreateAlert(props) {
    const [show, setShow] = useState(false);
    
    const closeUploadForm = () => {
        setShow(false);
    }
    const showUploadForm = () => {
        setShow(true);
    }

    // this is called when a new alert is created.
    const refreshAlerts = () => {
        console.log("refreshing alerts")
        ReactDOM.createRoot(document.getElementById("alerts-conatiner"))
        .render(<ShowAlerts userDetails={props.userDetails}/>)
    };
    
    return (
        
        <>
            <button onClick={showUploadForm} style={{backgroundColor:'transparent', cursor:'pointer', border:'0'}}>
                <Image src='/CreateAlert.png' alt='Create Alert' style={{maxHeight:'3rem'}} />
            </button>
            
            <Modal 
                show={show} 
                onHide={closeUploadForm} 
                size="lg" 
                backdrop="static"
            >
                <Modal.Header closeButton className='text-dark'>
                    <Modal.Title>Create a new alert/announcement.</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-dark'>
                    <DataForm docType='alerts' formAction='add' callbackSave={refreshAlerts} callbackCancel={(e)=>setShow(false)}/>
                </Modal.Body>

            </Modal>

        </>
        
    );
   
}

export default CreateAlert;