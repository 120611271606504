import { useState, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import firebase from './firebase';
import { Table } from 'react-bootstrap';

function JoinUs(props) {
    const [show, setShow] = useState(false);
    const uploadStatus = useRef();
    const formRef = useRef();

    const closeForm = () => {
        setShow(false);
    }
    const showForm = () => {
        setShow(true);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        uploadStatus.current.innerHTML="in progress ...";
        uploadStatus.current.style.color="blue";
       
        var uploadSucess = false;
        const form = event.currentTarget;
        
        
        await fetch(process.env.REACT_APP_API_URL+"/member/apply",
            {
                method: 'post',
                headers: {
                    'Content-Type':'application/json',
                    'X-Firebase-AppCheck': (await firebase.appCheck().getToken(/* forceRefresh= */ true)).token,
                },
                body: JSON.stringify({
                    name: form.name.value,
                    address: form.address.value,
                    phone: form.phone.value,
                    email: form.email.value,
                    career: form.career.value,
                    experience: form.experience.value,
                })
            }
        )
        .then(
            (res) => {
                if(res.ok){
                    uploadSucess = true;
                }else{
                    uploadSucess = false;
                }
                
            },
            (error) => {
                uploadSucess = false;
                console.error(error);
            }
        )
        
        if (uploadSucess) {
            uploadStatus.current.innerHTML="Thank you for applying. We will review your application and get back to you.";
        }  else{
            uploadStatus.current.innerHTML="Oops! Something went wrong. Please try after some time. ";
            uploadStatus.current.style.color="red";
        }
           
        formRef.current.style.display="none";
    }  

 
    return (
        <Container style={{display: props.display?"block":"none"}}>
            
            <Modal 
                show={show} 
                onHide={closeForm} 
                size="lg" 
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>New member application</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form  onSubmit={handleSubmit} ref={formRef}>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" autoFocus required/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="address">
                            <Form.Label>Address</Form.Label>
                            <Form.Control type="address" required/>
                        </Form.Group>  
                        <Form.Group className="mb-3" controlId="phone">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control type="tel" placeholder="123-456-7890" required/>
                        </Form.Group> 
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="name@example.com" required/>
                        </Form.Group>  
                        <Form.Group className="mb-3" controlId="career">
                            <Form.Label>Career</Form.Label>
                            <Form.Control type="text" />
                        </Form.Group> 
                        <Form.Group className="mb-3" controlId="experience">
                            <Form.Label>Prior Experience</Form.Label>
                            <Form.Control as="textarea" rows={3} />
                        </Form.Group>  
                        
                        <Button variant="dark" type="submit">
                            Submit
                        </Button>
                        
                    </Form>
                    <span ref={uploadStatus}></span>
                </Modal.Body>
            </Modal>    

            <Table bordered responsive className='m-2'>
                    <thead><th>Requirements</th></thead>
                    <tbody>
                        <tr>
                            <ul>
                                <li>Be at least 16 years of age or older</li>
                                <li>Be a United States citizen or legal resident</li>
                                <li>Reside within the West Side of Salisbury Township or Allentown's West End</li>
                                <li>Successfully pass a background check through the Pennsylvania State Police</li>
                                <li>Provide at least three reliable references for pre-approval consultation</li>
                            </ul>
                        </tr>
                    </tbody>
                    <thead><th>Benefits</th></thead>
                    <tbody>
                        <tr>
                            <ul>
                                <li>Training in firefighting strategies and tactics, cost free</li>
                                <li>Possessing the ability to aid your community during times of need</li>
                                <li>Contentment in answering the call to service and volunteering priceless time</li>
                                <li>Development of critical thinking and leadership skills </li>
                                <li>Experience in assessing and mitigating intense situations</li>
                                <li>Camaraderie among company personnel</li>
                                <li>Advancement of lifesaving skills that can be utilized throughout life</li>
                                <li>Attainment of official certifications in various operational domains </li>
                                <li>Uniform, equipment, and training allowances</li>
                                <li>Invaluable networking among emergency services professionals</li>
                            </ul>
                        </tr>
                    </tbody>
                    <thead><th>Process</th></thead>
                    <tbody>
                        <tr>
                            <ul>
                                <li>Complete and submit the interest form below</li>
                                <li>Conduct telephone interview and virtual informational session</li>
                                <li>Complete and submit the official membership application (provided via email following the telephone interview)</li>
                                <li>Successfully pass a Pennsylvania State Police background check</li>
                                <li>Successfully be approved by the company Board of Directors</li>
                                <li>Successfully complete a physical examination </li>
                                <li>Begin the probationary firefighter period</li>
                            </ul>
                        </tr>
                    </tbody>
                    <thead><th>Expectations</th></thead>
                    <tbody>
                        <tr>
                            <ul>
                                <li>Attend at least 75% of training sessions as a probationary firefighter, held every Monday from 6:30 PM-10:00 PM</li>
                                <li>Successfully complete the probationary checklist after six (6) months of active training</li>
                                <li>Enroll in a specialty course within the first six (6) months of membership dependent on the status of desired membership (i.e. EVOC/Pumps I & II, Firefighter I, etc.)</li>
                                <li>Respond to at least 10% of emergency calls each year once qualified to do so </li>
                                <li>Actively participate in company public relations events and special assignments </li>
                                <li>Show interest and initiative in learning the basics of the fire service</li>
                            </ul>
                        </tr>
                    </tbody>
                </Table>

            <div style={{textAlign:"right", margin:"5px"}}>
                <Button variant="primary" onClick={showForm}>
                    Join Us
                </Button>
            </div>
        </Container>
    );
  
}

export default JoinUs;