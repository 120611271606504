import { Container } from 'react-bootstrap';
import Config from './resources/config.json';

function Brand(props) {

    return (
        <>  
             
            <Container className="justify-content-center d-flex" style={{alignItems:'center'}} id="brand" >
                
                <a href='/' className='m-2 mt-0 mb-0'>
                    <img className="d-none d-sm-none d-md-block" src={Config.brand.logo} width="70" height="70"  alt=""/>
                    <img className="d-none d-sm-block d-md-none" src={Config.brand.logo} width="50" height="50"  alt=""/> 
                    <img className="d-block d-sm-none" src={Config.brand.logo} width="40" height="40"  alt=""/>                                      
                </a>
                {/* Use d-none & d-sm-block to show the text with screen size > sm */}
                <span className="h1 text-uppercase d-none d-sm-none d-lg-block">{Config.brand.name}</span> 
                <span className="h4 text-uppercase d-none d-sm-none d-md-block d-lg-none ">{Config.brand.name}</span>
                <span className="h5 text-uppercase d-none d-sm-block d-md-none">{Config.brand.name}</span> 
                <span className="h6 text-uppercase d-block d-sm-none">{Config.brand.name}</span>            
            </Container>

        </>
    
        );
}

export default Brand;