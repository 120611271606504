import { Component } from 'react';
import {Container, Nav, Navbar, NavDropdown, Offcanvas, Image, Row, Col } from 'react-bootstrap';
import Brand from './Brand';
import ImageLink from "./widgets/ImageLink";
import Config from './resources/config.json';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userSignedIn: false,
    };
    
  }
  
  scrollToBottom = () =>{  
    window.scrollTo({  
      top: document.documentElement.scrollHeight,  
      behavior: 'smooth'
    });  
  }; 


  render() {
    
    return (
      <>
        
        <Navbar 
          collapseOnSelect 
          key={this.props.expand} 
          expand={this.props.expand} 
          className="bg-black flex-nowrap" 
          sticky="top" 
          data-bs-theme="dark"
          style={{opacity:"1"}}
          >
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${this.props.expand}`} />

          <Container className="d-block d-md-block ">
            <Brand updateBody={this.props.updateBody} />

            <Container>               
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${this.props.expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${this.props.expand}`}
                placement="start"
                className="bg-black"
                data-bs-theme="dark"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${this.props.expand}`} >
                    <img src={Config.brand.logo} width="40" height="40"  alt=""/>    
                  </Offcanvas.Title> 
                </Offcanvas.Header>
                <Offcanvas.Body className='justify-content-center'>
                  <Nav className='text-uppercase' >
                    {
                      /*Config.postTypes.map((postType,idx) => (
                        <Nav.Link key={idx} href={"#"+postType.type} onClick={(e) => this.props.updateBody(e,postType.type)}>{postType.text}</Nav.Link>
                        ))*/
                        
                    }
                    <Nav.Link href={"#news_n_events"} onClick={(e) => this.props.updateBody(e,"news_n_events")}>News & Events</Nav.Link>
                    <Nav.Link href="#call_history" onClick={(e) => this.props.updateBody(e,"call_history")}>Call History</Nav.Link>
                    <Nav.Link href="#new_station" onClick={(e) => this.props.updateBody(e,"new_station")}>New Station</Nav.Link>                    
                    <Nav.Link href="#join_us" onClick={(e) => this.props.updateBody(e,"join_us")}>Join Us</Nav.Link>
                    <Nav.Link href="#contact_us" onClick={this.scrollToBottom}>Contact Us</Nav.Link>
                    <Nav.Link href="#donate" onClick={(e) => this.props.updateBody(e,"donate")}>Donate</Nav.Link>
                     
                    <NavDropdown
                      title="Explore"
                      id={`offcanvasNavbarDropdown-expand-${this.props.expand}`}
                    >
                      <NavDropdown.Item href="#apparatus" onClick={(e) => this.props.updateBody(e,"apparatus")}>Apparatus</NavDropdown.Item>
                      <NavDropdown.Item href="https://westernsalisbury.smugmug.com" target="_blank">Photos</NavDropdown.Item>
                      <NavDropdown.Divider />                   
                    </NavDropdown>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
                            
            </Container>
          </Container>       
        </Navbar>
        
        
        {/* Quick Links to be appeared on each page*/}
        <div style={{position:'relative', width:'100%'}}>
          <Image src={Config.brand.profilePic} alt="" fluid />
          {this.props.bodyType==='home'?<></>:
            <Row style={{top:'50%', left:'50%', position:'absolute', transform:'translate(-50%, -50%)',msTransform:'translate(-50%, -50%)', width:'20rem'}} className='g-1'>
            {/*
              Config.postTypes.map((postType,idx) => (
                <Col key={postType.type} style={{display:this.props.bodyType===postType.type?'none':''}}>
                  <ImageLink hideImage url="#" onClick={(e) => this.props.updateBody(e,postType.type)}  icon={postType.icon} />
                </Col>
              ))
              */}
              <Col key="news_n_events" >
                <ImageLink hideImage url="/" onClick={(e) => this.props.updateBody(e,'news_n_events')}  icon={`/static/News.png`} />
              </Col>
              <Col key="join_us" >
                <ImageLink hideImage url="/" onClick={(e) => this.props.updateBody(e,'join_us')}  icon={`/static/join_us.png`} />
              </Col>
              <Col key="photo_gallery" >
                <ImageLink hideImage url="https://westernsalisbury.smugmug.com"  icon={`/static/photo_icon.png`} target='_blank' />
              </Col>
            </Row>
        
          }  
        </div>


      </>
    );
  }
  
}

export default Header;

