import { useEffect } from "react";
import firebase from '../firebase';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';

const authUI = new firebaseui.auth.AuthUI(firebase.auth());
const uiConfig = {
  callbacks: {
    signInSuccessWithAuthResult: async function(authResult, redirectUrl) {
      // User successfully signed in.
      // Return type determines whether we continue the redirect automatically
      // or whether we leave that to developer to handle.
      
      return false; //dont redirect
    },
    uiShown: function() {
      // The widget is rendered.
      // Hide the loader.
      document.getElementById('loader').style.display = 'none';
      document.getElementById('firebaseui-auth-container').style.display = "block"; // hide the login container by default.
    }
  },
  // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
  signInFlow: 'popup',
  //signInSuccessUrl: process.env.REACT_APP_PUBLIC_URL,
  signInOptions: [
    // Leave the lines as is for the providers you want to offer your users.
    //firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    //firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    //firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    //firebase.auth.EmailAuthProvider.PROVIDER_ID,
    //firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    {
      provider: 'microsoft.com',
      providerName: 'Microsoft',
      // To override the full label of the button.
      // fullLabel: 'Login with Microsoft',
      buttonColor: '#2F2F2F',
      //iconUrl: '<icon-url-of-sign-in-button>',
      loginHintKey: 'login_hint',
      //scopes: [
      //  'mail.read'
      //],
      customParameters: {
        prompt: 'consent',
        tenant: 'fbba4033-c42f-4c09-af83-4215b6bf0979'
      }
    }
  ],
  // Terms of service url.
  //tosUrl: '<your-tos-url>',
  // Privacy policy url.
  //privacyPolicyUrl: '<your-privacy-policy-url>'
};  
 
//reset authUI to avoid initialization before the Login DOM object is loaded when the web page is refreshed.
authUI.reset(); 

//start the auth UI if user not signed in
//if(firebase.auth().currentUser == null)  {
//  authUI.start('#firebaseui-auth-container', uiConfig);
//}  

    
function Login(props)  {   
  
  useEffect(() => {
    //show login ui when user is signed out
    firebase.auth().onAuthStateChanged((user) => {
      if(!user) {
        authUI.start('#firebaseui-auth-container', uiConfig);
      }
    });
  },[]);

  return (    
      <> 
        <div style={{display: props.display?"block":"none"}}>        
          <div id="firebaseui-auth-container" ></div>
          <div id="loader">Loading Firebase Auth UI...</div>          
        </div>            
      </>      
  );  

}

export default Login;

