

export const authenticateFB = () => {
    const FB = window.FB;
    FB.getLoginStatus(function(response) {       
        if (response.status === 'connected') {
            console.log("Authenticated to FB")
        } else{
            console.log("not logged in to FB")
            FB.login(function(response) {
                if (response.authResponse) {
                    console.log("Authenticated to FB")
                } else {
                    console.log('User cancelled login or did not fully authorize.');
                }
            },{scope: 'pages_manage_posts'});
        }
    } );
    
}

export const createPost = (post, callback) => {
    const FB = window.FB;
    FB.getLoginStatus(function(response) {
        if (response.status === 'connected') {
            const auth = response.authResponse
            //get page access token
            FB.api(`${auth.userID}/accounts?access_token=${auth.accessToken}`, (response) => {

                if(response.data?.length>0){
                    
                    const page = response.data.filter((page) => {
                        return page.id===`${process.env.REACT_APP_FB_PAGE_ID}`;
                    })[0]

                    //post to the page
                    post['access_token'] = page.access_token  

                    FB.api(`/${page.id}/feed`, 'post', post, (response) => {
                        if(response.id) callback(response.id)
                        else{
                            throw new Error(`Post could not be created on Facebook. ${JSON.stringify(response)}` )
                        }
                    })
                
                }
            })
        }else{
            throw new Error("User is not authnticqated to Facebook.")
        }
    })
   
}



